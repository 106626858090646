<template>
  <div class="dnc-personal-info__input" :class="{'dnc-error': !!error}">
    <label class="dnc-input__title" :for="uniqueInputId" v-if="label">
      {{ label }} <span class="dnc-small" v-if="notRequired">необязательно</span>
    </label>
    <div class="dnc-input__form">
      <template v-if="type === 'select'">
        <Multiselect v-if="searchable" class="multiselect-old" v-model="value" :placeholder="placeholder" :options="options" :searchable="true"
                     :label="labelProp" v-bind="Object.keys($attrs).reduce((a, c) => {if (c !== 'class') a[c]=$attrs[c]; return a }, {})"
                     noOptionsText="Список пуст" noResultsText="Нет результатов" />
        <template v-else>
          <select v-model="value" :id="uniqueInputId">
            <option :value="null" disabled>{{ placeholder }}</option>
            <slot></slot>
          </select>
          <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.97055 9.50614C7.77408 9.50728 7.57931 9.46944 7.39742 9.39479C7.21553 9.32015 7.0501 9.21016 6.9106 9.07114L0.939046 3.07114C0.799851 2.93128 0.689436 2.76524 0.614105 2.58251C0.538773 2.39978 0.5 2.20393 0.5 2.00614C0.5 1.80835 0.538773 1.6125 0.614105 1.42976C0.689436 1.24703 0.799851 1.08099 0.939046 0.941137C1.07824 0.801279 1.24349 0.690337 1.42536 0.614647C1.60722 0.538956 1.80215 0.5 1.999 0.5C2.19585 0.5 2.39077 0.538956 2.57264 0.614647C2.75451 0.690337 2.91975 0.801279 3.05895 0.941137L7.97055 5.90614L12.8971 1.13614C13.0344 0.982702 13.2018 0.859389 13.3887 0.77391C13.5756 0.688431 13.7781 0.642625 13.9835 0.639356C14.1888 0.636087 14.3926 0.675425 14.5822 0.754911C14.7717 0.834398 14.9429 0.952323 15.085 1.10131C15.2271 1.2503 15.337 1.42714 15.408 1.62078C15.479 1.81442 15.5094 2.0207 15.4975 2.2267C15.4855 2.4327 15.4313 2.63401 15.3384 2.81802C15.2454 3.00204 15.1157 3.1648 14.9573 3.29614L8.98571 9.08614C8.7127 9.35061 8.34975 9.50077 7.97055 9.50614Z"
              fill="#9E9E9E"/>
          </svg>
        </template>
      </template>
      <template v-else-if="type === 'textarea'">
        <textarea v-model="value" :id="uniqueInputId"
                  :placeholder="placeholder" :maxlength="maxlength"></textarea>
        <div class="dnc-realized-projects__counter" v-if="maxlength">{{ value?.length ?? 0 }}/{{ maxlength }}</div>
      </template>
      <input v-else class="input" :type="type" v-model="value" :id="uniqueInputId" :placeholder="placeholder" :disabled="disabled">
      <slot name="icon"></slot>
      <span v-if="error" class="dnc-error-msg">{{ error }}</span>
    </div>
    <slot name="afterInput"></slot>
  </div>
</template>

<script setup>
import Multiselect from '@vueform/multiselect'

const props = defineProps(['label', 'modelValue', 'error', 'type', 'placeholder', 'afterMounted', 'notRequired', 'maxlength', 'searchable', 'labelProp', 'options', 'disabled'])
const emit = defineEmits(['update:modelValue'])

const uniqueInputId = 'input' + getCurrentInstance().uid
const value = computed({
  get: () => props.modelValue,
  set: val => emit('update:modelValue', val)
})

onMounted(() => (props.afterMounted ?? (() => {}))(uniqueInputId))
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.multiselect-old {
  --ms-bg: var(--color-bg);
  --ms-border-color: transparent;
  --ms-radius: 10px;
  --ms-py: 10px;
  --ms-px: 16px;
  --ms-ring-width: 0;
  --ms-placeholder-color: var(--color-text-dimmed);

  --ms-spinner-color: var(--color-text-dimmed);
  --ms-caret-color: var(--color-text-dimmed);
  --ms-clear-color: var(--color-text-dimmed);
  --ms-clear-color-hover: var(--color-text-dimmed);

  --ms-dropdown-bg: var(--color-bg);
  --ms-dropdown-border-color: var(--color-accent);
  --ms-dropdown-radius: 10px;

  --ms-option-bg-pointed: var(--color-secondary);
  --ms-option-bg-selected: var(--color-accent);
  --ms-option-bg-disabled: var(--color-bg);
  --ms-option-bg-selected-pointed: var(--color-accent);
  --ms-option-bg-selected-disabled: var(--color-secondary);
  --ms-option-color-pointed: var(--color-black);
  --ms-option-color-selected: var(--color-white);
  --ms-option-color-disabled: var(--color-text-dimmed);
  --ms-option-color-selected-pointed: var(--color-white);
  --ms-option-color-selected-disabled: var(--color-text-dimmed);
  --ms-option-py: 6px;
  --ms-option-px: 22px;

  max-width: 350px;
  margin: 0;
  font-family: Inter, sans-serif;
  font-weight: 500;

  .dnc-custom-style-6 & {
    max-width: 100%;
  }

  .dnc-custom-style-8 & {
    max-width: 100%;
  }

  &.is-active {
    --ms-border-color: var(--color-accent);
  }

  .dnc-error & {
    --ms-border-color: var(--color-error);
  }

  input {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    outline: none;
    box-sizing: border-box;
    border: 0;
    appearance: none;
    font-size: inherit;
    font-family: inherit;
    background: var(--ms-bg);
    border-radius: var(--ms-radius);
    padding-top: var(--ms-py);
    padding-bottom: var(--ms-py);
    padding-left: var(--ms-px);

    &:focus {
      border-color: transparent;
    }
  }

  .multiselect-caret {
    transition-duration: 0.15s;
  }

  .multiselect-placeholder {
    opacity: .6;
  }

  .multiselect-option {
    display: block;
    padding: 6px 16px;
    line-height: 22px;
  }

  .multiselect-single-label {
    display: block;
    width: 100%;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 64px;
    line-height: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.dnc-custom-style .multiselect-old {
  max-width: 100%;
}
</style>
